import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/effect-cards';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCards } from 'swiper/modules';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ProductCard from '../Components/ProductCard';
import ProductCarousel from '../Components/ProductCarousel';
import { motion } from 'framer-motion';

const HomePage = () => {
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [catProducts, setCatProducts] = useState([]);

    const [isLoading, setLoading] = useState(true);
    const observerRef = useRef(null);

    useEffect(() => {
        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show-css');
                } else {
                    entry.target.classList.remove('show-css');
                }
            });
        });
        const makeAPIcalls = async () => {
            setLoading(true)

            await axios.get('https://api.techcoredistribution.com/api/categories/').then((res) => {
                console.log(res.data);
                setCategories(res.data)

            })
            await axios.get('https://api.techcoredistribution.com/api/brands/').then((res) => {

                console.log(res.data);
                setBrands(res.data)

            })
            await axios.get('https://api.techcoredistribution.com/api/products/').then((res) => {

                console.log(res.data);
                setProducts(res.data)
                setCatProducts(res.data)

            })
            setLoading(false)

        }
        makeAPIcalls()
        return () => {
            // Clean up the observer when component unmounts
            const hiddenElements = document.querySelectorAll('.hidden-css');
            hiddenElements.forEach((el) => observerRef.current.unobserve(el));
        };
    }, []);

    useEffect(() => {
        // Observe hidden elements when loading is false
        if (!isLoading) {
            const hiddenElements = document.querySelectorAll('.hidden-css');
            hiddenElements.forEach((el) => observerRef.current.observe(el));
        }
    }, [isLoading]);

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Change to 3 for medium screens, 2 for small screens
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const brandSliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Change to 3 for medium screens, 2 for small screens
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const categoryProducts = async (id) => {
        await axios.get(`https://api.techcoredistribution.com/api/category-product/${id}`).then((res) => {
            console.log(res.data);
            setCatProducts(res.data)
        })
    }

    return (
        <>
            {
                isLoading ? console.log("printing true : ", isLoading) : console.log("printing false : ", isLoading)
            }
            {/* <div className='h-screen w-screen bg-white grid place-items-center'>
                        <img height={400} src="/assets/loading-animation.gif" alt="" />
                    </div> 
            */}
            {
                isLoading &&
                <div className='h-screen w-screen bg-white grid place-items-center'>
                    <img height={400} src="/assets/loading-animation.gif" alt="" />
                </div>
            }
            <div className='h-max w-[full] flex flex-col gap-10 pt-20 md:pt-12 homepage-section '>

                <center> <div className='w-full md:w-[95%]  rounded-xl mt-10 '>
                    <Swiper

                        spaceBetween={0}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        // pagination={{
                        //     clickable: true,
                        // }}
                        loop={true}
                        navigation={true}
                        modules={[Autoplay, Navigation]}
                        className="mySwiper hidden-css"
                    >
                        <SwiperSlide className='swiper-slide'><img className='object-cover rounded-lg ' src="/assets/vigo-banner-1.jpg" alt="" /></SwiperSlide>
                        {/* <SwiperSlide className='swiper-slide'><img className='object-cover rounded-lg' src="/assets/4.jpg" alt="" /></SwiperSlide> */}
                        <SwiperSlide className='swiper-slide'><img className='object-cover rounded-lg' src="/assets/mellow-banner-1.jpg" alt="" /></SwiperSlide>

                    </Swiper>
                </div>
                </center>


                <div className='w-full drop-shadow-xl grid place-items-center hidden-css'>
                    <div className='w-11/12 h-max  flex flex-col lg:flex-row items-center justify-center p-5 rounded-lg '>
                        <div className='h-full w-11/12 lg:w-1/2 flex flex-col justify-center gap-5 items-center'>
                            <p className='uppercase text-center lg:text-left text-xl md:text-2xl lg:text-3xl font-black text-zinc-700 w-2/3'>About Us</p>
                            <p className='w-full lg:w-2/3 font-medium text-justify text-xs md:text-sm lg:text-base'>Welcome to <span className='font-bold'>Tech Core Distribution LLC! </span><br /> We're not just your average distribution company – we're your go-to source for all things tobacco alternative. From a wide range of vape-related goodies to other smoke-related items, we've got you covered. <br /> <span className='font-bold'>What sets us apart? </span><br />Our commitment to top-notch quality, pocket-friendly prices, and speedy deliveries that'll make your head spin (in a good way!). At Tech Core LLC, we're all about giving you the best bang for your buck without compromising on excellence. Join us in exploring a world of smoking alternatives that's high on quality and low on hassle!</p>
                        </div>
                        <div className='w-11/12 lg:w-1/2 grid place-items-center'>
                            <img className='h-2/3 rotate-[-40deg] drop-shadow-xl' src="/assets/about-us-vape.png" alt="" />
                        </div>
                    </div>
                </div>


                <div className='h-full w-full  flex flex-col lg:flex-row items-center justify-center gap-5 p-5 z-[2] hidden-css'>
                    <div
                        className='w-4/5 lg:w-1/4 h-64 bg-cyan-950 grid place-items-center text-white rounded-lg container-1 
                        bg-[url("/public/assets/3.jpg")]
                        bg-cover bg-no-repeat bg-center shadow-[0px_0px_10px_black] '>

                    </div>
                    <div
                        className='w-4/5 lg:w-1/4 h-64 bg-rose-950 grid place-items-center text-white rounded-lg container-2 
                        bg-[url("/public/assets/2.jpg")]
                        bg-cover bg-no-repeat bg-center shadow-[0px_0px_10px_black]'>


                    </div>

                    <div className='w-4/5 lg:w-1/4 h-64 bg-emerald-950 grid place-items-center text-white rounded-lg container-3
                    bg-[url("/public/assets/6.jpg")]
                    bg-cover bg-no-repeat bg-right shadow-[0px_0px_10px_black]'>

                    </div>

                </div>
                <div className='w-full h-max grid place-items-center z-[1]'>
                    <p className='text-3xl font-black text-slate-600 uppercase'>Products</p>
                    <div className='w-11/12 h-max flex flex-wrap items-center justify-center gap-5 py-3 md:p-5 text-white'>
                        {
                            categories.map(category => (
                                <button key={category.id}
                                    onClick={() => {
                                        categoryProducts(category.id)
                                    }
                                    }
                                    className='bg-slate-700 w-2/5 lg:w-1/6 h-max rounded-lg font-bold p-2 hover:bg-red-500 whitespace-nowrap text-sm lg:text-md'>
                                    {category.category_name}
                                </button>
                            ))
                        }
                    </div>
                </div>

                <center className='w-full z-[1] hidden-css'>
                    <div className='w-4/5'>
                        <>
                            <Slider {...settings}>
                                {catProducts.length > 0 ? catProducts.map((product) => (
                                    <Link to={'/product/' + product.id} key={product.id} >
                                        <ProductCard key={product.id} id={product.id} name={product.product_name} imgUrl={product.product_thumbnail} />
                                    </Link>
                                )) : <div className='font-black text-md uppercase'>No Products available</div>
                                }
                            </Slider>
                        </>

                    </div>
                </center>


                <div className='w-full h-max flex flex-col justify-center items-center gap-5 p-5 z-[1]'>
                    <p className='text-3xl font-black text-slate-600 uppercase'>Brands</p>
                    {/* <div className='w-full h-max flex flex-wrap items-center justify-center gap-5  text-white font-bold uppercase text-sm'>
                        {
                            brands.map(brand => (
                                <button
                                    key={brand.id}
                                    onClick={() => {
                                        getBrandProducts(brand.id)
                                    }}
                                    className='grid place-items-center px-1 py-3  bg-slate-700 hover:bg-red-500 w-[25%] md:w-[25%] lg:w-[10%] rounded-lg whitespace-nowrap '>
                                    <p>{brand.brand_name}</p>
                                </button>
                            ))
                        }

                    </div>

                    <center className='w-full'>
                        <div className='w-4/5'>
                            <>
                                <Slider {...settings}>
                                    {brandProducts.length > 0 ? brandProducts.map((product) => (
                                        <Link to={'/product/' + product.id} key={product.id} >
                                            <ProductCard key={product.id} id={product.id} name={product.product_name} imgUrl={product.product_thumbnail} />
                                        </Link>
                                    )) : <div className='font-black text-md uppercase'>No Products available</div>
                                    }
                                </Slider>
                            </>

                        </div>
                    </center> */}

                    <center className='w-full z-[1] hidden-css'>
                        <div className='w-4/5'>
                            <>
                                <Slider {...brandSliderSettings} >
                                    {
                                        brands.map((brand) => (
                                            <div key={brand.id} className='w-1/3 flex items-center justify-center'>
                                                <img className='w-[45%] md:w-[50%] lg:w-[60%] object-contain drop-shadow-[0px_0px_3px_#5555559a] ' src={"https://api.techcoredistribution.com" + brand.brand_logo} alt="" />
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </>

                        </div>
                    </center>
                </div>
                <center> <div className='w-[90%] lg:w-[80%]  rounded-xl pb-5 drop-shadow-[0px_0px_10px_gray] hidden-css'>
                    <Swiper

                        spaceBetween={0}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        // pagination={{
                        //     clickable: true,
                        // }}
                        loop={true}
                        navigation={true}
                        modules={[Autoplay, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide className='swiper-slide'><img className='object-cover rounded-lg ' src="/assets/banner-4.jpg" alt="" /></SwiperSlide>
                        <SwiperSlide className='swiper-slide'><img className='object-cover rounded-lg' src="/assets/banner-1.jpg" alt="" /></SwiperSlide>
                        <SwiperSlide className='swiper-slide'><img className='object-cover rounded-lg' src="/assets/banner-3.jpg" alt="" /></SwiperSlide>

                    </Swiper>
                </div>
                </center>
                {/* <div className='w-[full] h-[50vh] bg-gray grid place-items-center z-[1]'>
                    <div className='w-11/12 h-max flex flex-col lg:flex-row justify-between items-center gap-5'>
                        <div className='h-full w-11/12 lg:w-1/2 flex flex-col justify-center gap-8 items-center'>
                            <p className='text-3xl font-black text-zinc-700 w-2/3 uppercase'>Our Distributor</p>
                            <p className='w-2/3 font-medium text-neutral-700 '>SOUTH TEXAS MERCHANTS ASSOCIATION (STMA) WHOLESALE
                                South Texas Merchants Association is a member run co-operative for gas station and convenience store owners. STMA was established in the year 2000 with a handful gas stations and today we stand tall with close to 400 member stores.</p>
                        </div>
                        <div className='w-1/2  grid place-items-center'>
                            <img className='w-1/3' src="/assets/stma.png" alt="" />
                        </div>


                    </div>

                </div> */}
            </div>
            {/* } */}

        </>
    )
}

export default HomePage
