import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { useParams, useLocation, Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductCard from '../Components/ProductCard';

const ProductPage = (props) => {
    const { id } = useParams();
    const [product, setProduct] = useState([]);
    const [productFeatures, setProductFeatures] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [RProducts, setRProducts] = useState([])
    const observerRef = useRef(null);


    useEffect(() => {

        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {

                if (entry.isIntersecting) {

                    entry.target.classList.add('show-css');
                }
                else {
                    entry.target.classList.remove('show-css')
                }
            });
        });

        const makeAPIRequest = async () => {
            console.log(id)
            setLoading(true)
            await axios.get(`https://api.techcoredistribution.com/api/product/${id}`).then((res) => {
                console.log(res.data);
                setProduct(res.data)
            })
            await axios.get(`https://api.techcoredistribution.com/api/features/${id}`).then((res) => {
                console.log(res.data);
                setProductFeatures(res.data)
            })
            await axios.get(`https://api.techcoredistribution.com/api/images/${id}`).then((res) => {
                console.log(res.data);
                setProductImages(res.data)
            })
            await axios.get(`https://api.techcoredistribution.com/api/products/`).then((res) => {
                console.log(res.data);
                setRProducts(res.data)
            })
            setLoading(false)
        }
        makeAPIRequest()


        return () => {
            // Clean up the observer when component unmounts
            const hiddenElements = document.querySelectorAll('.hidden-css');
            hiddenElements.forEach((el) => observerRef.current.unobserve(el));
        };
    }, [id]);

    useEffect(() => {
        // Observe hidden elements when loading is false
        if (!loading) {
            const hiddenElements = document.querySelectorAll('.hidden-css');
            hiddenElements.forEach((el) => observerRef.current.observe(el));
        }
    }, [loading]);

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Change to 3 for medium screens, 2 for small screens
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <>
            {
                loading &&
                <div className='h-screen w-screen bg-white grid place-items-center'>
                    <img height={400} src="/assets/loading-animation.gif" alt="" />
                </div>
            }
            <section className='pt-0 lg:pt-8 pb-32 min-h-screen product-detail-page '>


                <div className='hidden md:block pt-12 relative'>
                    <div className='flex flex-row'>
                        <div className='w-1/2 lg:w-1/3 relative flex justify-start'>
                            <div className='relative w-full'>
                                <div className='fixed w-1/2 lg:w-1/3'>
                                    <div className="w-full bg-white rounded-tr-lg rounded-br-lg drop-shadow-2xl flex items-center justify-center flex-col hidden-css">
                                        <img className=' aspect-square w-3/4 object-contain ' src={'https://api.techcoredistribution.com/' + product.product_thumbnail} alt="" />
                                        <p className='font-black text-gray-400 text-lg text-center'>BY</p>
                                        <img className='w-1/3' src={'https://api.techcoredistribution.com/media/' + product.product_brand_logo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/2 lg:w-2/3 '>
                            <div className='p-10 ml-10 '>
                                <div className='flex flex-col gap-10 hidden-css'>
                                    <p className='font-black text-3xl'>{product.product_name}</p>
                                    <p className='w-full lg:w-1/2 text-md font-light '>{product.product_detail}</p>
                                    <div className='p-2 '>
                                        <p className='text-lg font-bold '>Features</p>
                                        <ul className='font-light w-1/2 lg:w-8/12'>
                                            {
                                                productFeatures.map(feature => (
                                                    <li key={feature.id}>{feature.product_feature}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>

                                    {
                                        productImages.map((image) => (
                                            <div key={image.id} className='flex items-center justify-center hidden-css  '>
                                                <img className='object-contain w-full lg:w-1/3' src={"https://api.techcoredistribution.com" + image.product_image} alt="" />
                                            </div>
                                        ))
                                    }
                                </div>
                                <p className=' text-black text-center  p-10 font-black text-xl uppercase'>Other Products</p>

                                <center className='hidden-css'>
                                    <div className='w-4/5'>
                                        <>
                                            <Slider {...settings}>
                                                {RProducts.map((p) => (
                                                    <Link to={'/product/' + p.id} key={p.id}>
                                                        {
                                                            <ProductCard id={p.id} name={p.product_name} imgUrl={p.product_thumbnail} />
                                                        }
                                                    </Link>
                                                ))}
                                            </Slider>
                                        </>

                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>

                </div>
                {

                    <div className='block md:hidden w-screen h-max pt-20 z-10 '>
                        <div className='h-max w-screen bg-white flex flex-row  items-center justify-evenly rounded-lg drop-shadow-xl gap-1 fixed z-[9] hidden-css'>
                            <img className=' aspect-square w-[20vh] object-contain' src={'https://api.techcoredistribution.com/' + product.product_thumbnail} alt="" />
                            <p className='font-black text-gray-400 text-md font-mono text-center'>BY</p>
                            <img className='w-[15vh]  aspect-square object-contain' src={'https://api.techcoredistribution.com/media/' + product.product_brand_logo} alt="" />
                        </div>
                        <div id='name-div' className='w-full lg:w-8/12  p-10 relative top-44 hidden-css '>
                            <div className='flex flex-col gap-10'>
                                <p className='font-black text-2xl text-center'>{product.product_name}</p>
                                <p className='w-full lg:w-1/2 text-md font-light'>{product.product_detail}</p>
                                <div className='p-2'>
                                    <p className='text-lg font-bold '>Features</p>
                                    <ul className='font-light'>
                                        {
                                            productFeatures.map(feature => (
                                                <li key={feature.id}>{feature.product_feature}</li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                {
                                    productImages.map((image) => (
                                        <div key={image.id} className=' flex flex-col items-center justify-center gap-5 hidden-css'>
                                            <img className='object-contain w-full lg:w-1/3' src={"https://api.techcoredistribution.com" + image.product_image} alt="" />
                                        </div>

                                    ))
                                }

                            </div>
                            <p className=' text-black text-center  p-10 font-black text-xl uppercase'>Other Products</p>

                            <center className='hidden-css'>
                                <div className='w-4/5'>
                                    <>
                                        <Slider {...settings}>
                                            {RProducts.map((p) => (
                                                <Link to={'/product/' + p.id + '/#name-div'} key={p.id}>
                                                    {
                                                        product.id !== p.id ? <ProductCard id={p.id} name={p.product_name} imgUrl={p.product_thumbnail} /> : <></>
                                                    }
                                                </Link>
                                            ))}
                                        </Slider>
                                    </>

                                </div>
                            </center>
                        </div>

                    </div>
                }


            </section >
        </>
    )
}

export default ProductPage