import React, { useState } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import Distributors from '../Pages/Distributors';
const NavBar = () => {
    const [isMobileMenu, setIsMobileMenu] = useState(false)

    const toggleMobileMenu = () => {
        setIsMobileMenu(!isMobileMenu);
    };

    return (
        <>
            <nav className='hidden md:block w-screen  bg-white text-slate-700 self-center font-bold fixed top-0 left-0 z-10 drop-shadow-lg mb-44'>
                <div className='w-full h-full flex flex-row justify-center items-center p-2 gap-20 uppercase text-md '>
                <Link to={'/'} className='hover:cursor-pointer'> <div>
                        <img className='h-16' src="/assets/techcore-distribution-logo.png" alt="" />
                    </div></Link>
                    <div className='flex gap-10 text-md'>
                        <Link className='hover:text-red-500' onClick={toggleMobileMenu} to='/'>Home</Link>
                        {/* <Link className='hover:text-red-500' onClick={toggleMobileMenu} to='/Distributors'>Distributors</Link> */}
                        <div className="relative inline-block text-left cursor-pointer">
                            <div className='flex justify-center items-center gap-1'>
                                <Link to='/products' >
                                    <p className='hover:text-red-500'>Products</p>
                                </Link>

                            </div>
                            <div className="absolute left-[-30px] mt-0 w-max bg-white rounded-lg shadow-lg hidden text-slate-700 ">
                                <ul className="pt-2">
                                    <li className='py-3'>
                                        <Link
                                            className="block px-4 py-3 rounded-sm hover:bg-red-500 hover:text-gray-100"
                                            to='/products'>
                                            Batteries
                                        </Link>
                                    </li>
                                    <li className=''>
                                        <Link
                                            className="block px-4 py-3 rounded-sm hover:bg-red-500 hover:text-gray-100"
                                            to='/Products'>
                                            Disposable Vapes
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <Link className='hover:text-red-500 transition-all ease-in' onClick={toggleMobileMenu} to='/contact'>Contact</Link>



                    </div>
                    {/* <div>
                        <button className='bg-blue-900 px-4 py-2 rounded-lg border-[1px] border-gray-500  text-white'> Chat With Us</button>
                    </div> */}
                </div>
            </nav >


            <nav className='block md:hidden w-screen h-max bg-white text-slate-700 self-center font-semibold fixed top-0 left-0 z-10 '>
                <div className='w-full h-full flex flex-row justify-between items-center p-5 '>
                    <Link to={'/'} className='hover:cursor-pointer'> <div>
                        <img className='h-12' src="/assets/techcore-distribution-logo.png" alt="" />
                    </div></Link>
                    <div onClick={toggleMobileMenu} className='transition cursor-pointer bg-white hover:bg-white p-2 drop-shadow-lg rounded-md hover:drop-shadow-lg'>
                        <svg
                            className="h-6 w-6"
                            fill="none" stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            {isMobileMenu ? (
                                <path
                                    strokeLinecap="round" strokeLinejoin="round"
                                    strokeWidth="2" d="M6 18L18 6M6 6l12 12"
                                />
                            ) : (
                                <path
                                    strokeLinecap="round" strokeLinejoin="round"
                                    strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"
                                />
                            )}
                        </svg>
                    </div>
                </div>
                {
                    isMobileMenu ? <div className='bg-white'>
                        <div className='flex flex-col items-start justify-center gap-3 text-sm p-5'>
                            <Link onClick={toggleMobileMenu} to="/">Home</Link>
                            {/* <div className="relative inline-block text-left cursor-pointer">
                                <div className='flex justify-center items-center gap-1'>
                                    <p>Services</p>
                                    <img className='h-3 mt-1' src="/images/white-arrow.png" alt="" />
                                </div>
                                <div className="absolute left-[0px] mt-0 w-max bg-gray-100 rounded-lg shadow-lg hidden text-stone-800">
                                    <ul className="">
                                    </ul>
                                </div>
                            </div> */}

                            <Link onClick={toggleMobileMenu} to="/distributors">Distributors</Link>
                            <Link onClick={toggleMobileMenu} to="/Products">Products</Link>
                            <Link onClick={toggleMobileMenu} to="/contact">Contact</Link>

                        </div>
                        {/* <div className='p-2 grid place-items-center'>
                            <button className='bg-blue-900 py-2 w-1/2 rounded-lg border-[1px] border-gray-500  text-white'> Chat With Us</button>
                        </div>  */}
                    </div> : null
                }
            </nav>
        </>
    )
}

export default NavBar