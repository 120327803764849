import React from 'react'
import { Link } from 'react-router-dom'
const PrivacyPolicy = () => {
    return (
        <section className=' p-5 pt-24 min-h-screen'>
            <p className=' text-center text-4xl font-black uppercase bg-clip-text bg-gradient-to-b from-slate-700 to-red-500 text-transparent'>Privacy Policy</p>
            <div className='flex flex-col gap-3'>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '>1. Introduction</div>
                    This Privacy Policy is provided by Tech Core Wholesale LLC and governs the privacy of the users of our website. In this Privacy Policy, we explain how we collect, use, disclose, and protect your personal information.

                </div>

                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '>2. Information We Collect</div>
                    We may collect and process the following personal information about you:

                    Information that you provide when filling out forms on our Website, including but not limited to your name, email address, phone number, and address.
                    Information provided when you communicate with us, whether through email, phone, or other means.
                    Information about your use of our Website, including IP address, browser type, operating system, and other technical details.

                </div>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '> 3. Use of Your Information</div>
                    We may use your personal information for the following purposes:

                    To provide you with the products or services you have requested.
                    To improve our Website and the services we offer.
                    To respond to your inquiries, comments, or requests.
                    To send you marketing or promotional materials, only if you have explicitly consented to such communications.

                </div>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '>4. Disclosure of Your Information</div>
                    We may share your personal information with:

                    Our trusted service providers who assist us in operating our Website and delivering our services.
                    Legal authorities if required by law or to protect our rights, privacy, safety, or property.

                </div>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '> 5. Security</div>
                    We are committed to ensuring that your personal information is secure. We use industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.

                </div>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '> 6. Cookies</div>
                    We may use cookies to enhance your experience on our Website. You can adjust your browser settings to refuse cookies if you prefer.

                </div>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '>7. Links to Other Websites</div>
                    Our Website may contain links to third-party websites. We have no control over and are not responsible for the content or privacy practices of these websites. We encourage you to read the privacy policies of any linked websites.

                </div>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '> 8. Changes to this Privacy Policy</div>
                    We reserve the right to update this Privacy Policy at any time. The latest version will always be available on our Website.

                </div>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg whitespace-nowrap '>9. Contact Information</div>
                    If you have any questions or concerns about this Privacy Policy or the way we handle your personal information, please <Link className='font-bold text-red-500' to={'/contact'}> Contact us</Link>

            </div>



        </div>

            This revised privacy policy template serves as a general framework, and you should review it with a legal professional to ensure compliance with local regulations and accurate representation of your data handling practices.
        </section >
    )
}

export default PrivacyPolicy