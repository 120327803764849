import React, { useState } from 'react'
import axios from 'axios';

const Contact = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log(entry)
      if (entry.isIntersecting) {
        entry.target.classList.add('show-css');
      }
      else {
        entry.target.classList.remove('show-css')
      }
    });
  });

  const hiddenElements = document.querySelectorAll('.hidden-css');
  hiddenElements.forEach((el) => observer.observe(el));

  const [contactSuccess, setContactSuccess] = useState('')
  const [contactData, setContactData] = useState({
    'f_name': '',
    'email': '',
    'p_number': '',
    'message': '',
    'service': '',
    'status': '',
  })
  const handleChange = (event) => {
    setContactData({
      ...contactData, [event.target.name]: event.target.value
    })
    console.log(event.target.value)
  }

  const submitForm = async () => {
    const contactFormData = new FormData();
    contactFormData.append('f_name', contactData.f_name)
    contactFormData.append('email', contactData.email)
    contactFormData.append('p_number', contactData.p_number)
    contactFormData.append('message', contactData.message)

    try {
      console.log(contactFormData);
      await axios.post(
        'https://api.techcoredistribution.com/api/contact/',
        contactFormData).then((response) => {
          setContactData({
            'f_name': '',
            'email': '',
            'p_number': '',
            'message': '',
            'service': '',
            'status': 'success',
          });
          console.log(response.status)
          response.status === 200 ? setContactSuccess('success') : setContactSuccess('failed')
        });

    }
    catch (error) {
      console.log("Error", error);
      setContactData({ 'status': 'error' });
    }

  }

  return (
    <>
      <section className=' w-full h-max pt-20 md:pt-20 flex flex-col items-center justify-center gap-5 contact-section '>

        <div className=''>
          <p className='text-center text-4xl font-black uppercase bg-clip-text bg-gradient-to-tr from-slate-500 to-teal-600 text-transparent transition-all ease-in-out p-5 z-[1]'> Contact Us </p>
        </div>

        <div className='text-center w-full md:w-1/2 flex flex-col items-center justify-center bg-white drop-shadow-xl rounded-lg p-5 '>
          <div className='w-full flex flex-col md:flex-row gap-5 p-5 justify-center mt-5'>
            <input
              className='w-full md:w-1/2 bg-gray-300 px-3 py-2  rounded-lg'
              type="text"
              name='f_name'
              value={contactData.f_name}
              onChange={handleChange}
              placeholder='Name' />

            <input
              className='w-full md:w-1/2 bg-gray-300 px-3 py-2  rounded-lg'
              type="email"
              name='email'
              value={contactData.email}
              onChange={handleChange}
              placeholder='Email' />

          </div>
          <div className='w-full flex flex-col md:flex-row gap-5 p-5 justify-center'>
            <input
              className='w-full md:w-1/2 bg-gray-300 px-3 py-2 rounded-lg'
              type="tel"
              name='p_number'
              value={contactData.p_number}
              onChange={handleChange}
              placeholder='Phone Number' />

            <textarea
              className='w-full md:w-1/2 bg-gray-300 px-3 py-2  rounded-lg'
              type="text"
              name='message'
              value={contactData.message}
              onChange={handleChange}
              rows={2}
              placeholder='Message' />

          </div>
          <button
            className='bg-slate-700 text-white font-bold w-3/4 md:w-1/2 px-5 py-3 transition-all ease-in-out  rounded-lg m-5 hover:bg-red-900'
            onClick={submitForm}
          >SUBMIT</button>
        </div>
        <div className='z-[1] font-bold'>
          {
            contactSuccess === 'success' ? <p className='text-green-800'>Thankyou for Contacting Us.</p> : null
          }
          {
            contactSuccess === 'failed' ? <p className='text-red-800'>There is something wrong. <br /> Please try again later.</p> : null
          }
        </div>
        <div>
          <p className='font-black z-[1]'>OR</p>
        </div>
        <div className='flex flex-col lg:flex-row w-1/2 md:w-1/5 lg:w-1/3  h-max gap-10 text-center transition-all ease-in-out py-5'>
          <div className='w-full bg-white h-max rounded-lg drop-shadow-xl p-5 transition-all ease-in-out hover:drop-shadow-2xl hover:cursor-pointer hover:scale-105 '>
            <p className='font-bold'>
              Email us:
            </p>
            <p className='font-light'>
              techcore@email.com
            </p>
          </div>
          <div className='w-full bg-white h-max rounded-lg drop-shadow-xl p-5 transition-all ease-in-out hover:drop-shadow-2xl hover:cursor-pointer hover:scale-105'>
            <p className='font-bold'>
              Give us a call:
            </p>
            <p className='font-light'>
              +1 234 567 890
            </p>
          </div>

        </div>

      </section>
    </>
  )
}

export default Contact