import React from 'react'

const TermsNcond = () => {
    return (
        <section className='w-full flex flex-col  pt-24 min-h-[82vh] bg-[url("/public/assets/background.jpg")] bg-center bg-repeat bg-contain '>
            <div className='text-justify p-5 flex flex-col justify-between gap-5 before:absolute before:w-full before:bg-wh '>

                <div className='font-bold text-4xl text-center pb-3  '>  <p className=' text-center text-4xl font-black uppercase bg-clip-text bg-gradient-to-b from-slate-700 to-red-500 text-transparent'>Terms and Conditions</p></div>
                <div className='w-3/4'>
                    By using this site, you agree to the following terms and conditions.If you disagree, please discontinue using this site.We reserve the right to modify these terms at our discretion.
                </div>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '>Trademark and Copyright</div>
                    <li>All trademarks, logos, and content on this site belong to Worldwide Vape and its providers.Unauthorized use, including reproduction and distribution, is prohibited without our authorization.</li>
                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '> Copyright Infringement Claims</div>
                    <li>To report copyright infringement, contact our customer service with your details and evidence.We follow the law, and claims should be sent only to us.</li>

                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '> Liability</div>
                    <li>We are not liable for consequential damages, but local laws may vary.</li>
                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '>Site Restrictions</div>
                    <li>Use this site only as allowed by Worldwide Vape.Prohibited actions include hyperlinking, co - branding, and use of our trademarks.</li>
                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '>Hyperlinks</div>
                    <li>We are not responsible for websites that hyperlink to ours.</li>
                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '>Proper Battery Placement</div>
                    <li>Buyers are responsible for safe battery use.We are not liable for mishandling or accidents involving batteries.Contact us or read our Battery Safety section for guidance. </li>
                </div>
                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '>Indemnity</div>
                    <li>You agree to indemnify Worldwide Vape and its affiliates for any violations of these terms and conditions, whether by you or others using your account.</li>
                </div>

            </div>



        </section >

    )
}

export default TermsNcond