import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <div className='w-full h-max grid place-items-center border-t-2 border-neutral-500 footer mt-2 mb-32 md:mb-0 bottom-0 bg-white'>
            <div className='w-4/5 h-min flex flex-col lg:flex-row gap-5 md:gap-10 bg-white p-5 justify-around items-center'>
                <div className='w-1/3'>
                    <img className='w-full lg:w-1/2' src="/assets/techcore-distribution-logo.png" alt="" />
                </div>

                <div className='flex flex-col w-1/3 gap-5 items-center justify-center whitespace-nowrap text-center text-sm md:text-base'>

                    <div className='flex flex-col md:flex-row gap-5 whitespace-nowrap '>
                        <Link to={'/privacy-policy'} className='hover:font-medium'>Privacy Policy</Link>
                        <Link to={'/terms-and-conditions'} className='hover:font-medium'>Terms and Condtions</Link>
                        <Link to={'/age-policy'} className='hover:font-medium'>Age Policy</Link>
                        <Link to={'/Battery-safety'} className='hover:font-medium'>Battery Safety</Link>
                    </div>

                    <div className=''>©2023 <span className='font-semibold '>TechCore Distributions</span>– All Rights Reserved</div>
                </div>

                <div className='w-1/3'>
                    <div className='flex flex-row items-center justify-center gap-5'>
                        <img className='w-[30%] lg:w-[10%]' src="/assets/instagram-icon.png" alt="" />
                        <img className='w-[30%] lg:w-[10%]' src="/assets/facebook-icon.png" alt="" />
                        <img className='w-[30%] lg:w-[10%]' src="/assets/linkedin-icon.png" alt="" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer