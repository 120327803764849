import React from 'react'
import { Link } from 'react-router-dom'
import ProductPage from '../Pages/ProductPage'

const ProductCard = ({ id, name, imgUrl, handleClick }) => {
    return (

        <div key={id}
            onClick={handleClick}
            className='product-card-div flex flex-col items-center justify-center gap-2 bg-white min-h-80 max-h-80  rounded-lg aspect-square shadow-lg
            drop-shadow-xl hover:drop-shadow-[0px_0px_2px_red] transition-all ease-in m-5   text-white hover:scale-105'>
            <img
                className='aspect-square object-contain rounded-lg max-h-56 bg-white'
                src={"https://api.techcoredistribution.com" + imgUrl} alt="" />
            <p className='product-card-name-div w-full h-max p-4 mt-6 rounded-lg text-sm lg:text-md text-center font-bold bg-slate-800 hover:bg-red-500 bg-opacity-95'>{name}</p>

        </div>
    )
}

export default ProductCard