import React from 'react'

const BatterySafety = () => {
    return (
        <section className='w-full flex flex-col  pt-24 min-h-[82vh] bg-[url("/public/assets/background.jpg")] bg-center bg-repeat bg-contain '>
            <div className='text-justify p-5 flex flex-col justify-between gap-5 before:absolute before:w-full before:bg-wh '>

                <p className=' text-center text-4xl font-black uppercase bg-clip-text bg-gradient-to-b from-slate-700 to-red-500 text-transparent'> Battery Safety Guidelines </p>
                <div className='w-3/4'>
                    At Tech Core Wholesale LLC, we prioritize your safety when using our products, including mods, e-liquids, and batteries. Here are some essential battery safety tips:
                </div>
                <div className='flex flex-col gap-1' >
                    <div className='font-semibold text-lg '><li>Use a Battery Case</li></div>
                    Protect your batteries from short circuits by storing them in a battery case, not loose in your bag or pocket.
                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '><li> Use the Right Charger</li></div>
                    Only use the charger that came with your device to prevent overcharging and overheating.
                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '> <li>Follow Manufacturer Guidelines</li></div>
                    Read and follow the guidelines provided by the manufacturer to ensure safe use and maintenance of your vape device..
                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '><li>Avoid Overcharging</li></div>
                    Charge your batteries in your presence and avoid overnight charging to prevent battery weakening.
                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '><li> Dispose of Damaged Batteries</li></div>
                    Safely dispose of batteries that are damaged, frayed, or can't hold a charge.
                </div>

                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '><li>Proper Battery Placement</li></div>
                    Always follow the manufacturer's polarity markings to avoid potential venting and fires.
                </div>
                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '><li>Store in a Cool, Dry Place</li></div>
                    Keep batteries away from extreme temperatures and moisture to prevent damage.

                </div>
                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-lg '><li>Avoid Counterfeit Batteries</li></div>
                    Purchase batteries from reputable vendors to reduce the risk of combustion.
                </div>
                Your safety is our priority. Please adhere to these guidelines to enjoy your vaping experience responsibly.
            </div>
        </section >
    )
}

export default BatterySafety