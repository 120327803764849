import React from "react";
import NavBar from "./Components/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Distributors from "./Pages/Distributors";
import Contact from "./Pages/Contact";
import Products from "./Pages/Products";
import ProductPage from "./Pages/ProductPage";
import Footer from "./Components/Footer";
import TermsNcond from "./Pages/TermsNcond";
import AgePolicy from "./Pages/AgePolicy";
import BatterySafety from "./Pages/BatterySafety";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {
 
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Distributors" element={<Distributors />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Product/:id" element={<ProductPage />} />
          <Route path="/Terms-and-Conditions" element={<TermsNcond />} />
          <Route path="/Age-Policy" element={<AgePolicy />} />
          <Route path="/Battery-Safety" element={<BatterySafety />} />
          <Route path="/Privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
