import React, { useState, useEffect, useRef } from 'react';
import 'axios'
import axios from 'axios';
import ProductCard from '../Components/ProductCard';
import { Link } from 'react-router-dom';
const Products = () => {
  const [productsData, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true)
  const observerRef = useRef(null);

  const handleCheckboxChange = (option) => {
    setSelectedOption(option);
  };
  const getAllProducts = async () => {
    await axios.get('https://api.techcoredistribution.com/api/products/').then((res) => {
      console.log(res.data);
      setProducts(res.data)
    })
  }
  const getBrands = async () => {
    axios.get('https://api.techcoredistribution.com/api/brands/').then((res) => {
      console.log(res.data);
      setBrands(res.data)
    })
  }
  const getCategories = async () => {
    await axios.get('https://api.techcoredistribution.com/api/categories/').then((res) => {
      console.log(res.data);
      setCategories(res.data)
    })
  }

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show-css');
        }
        else {
          entry.target.classList.remove('show-css')
        }
      });
    });
    const makeAPIcalls = async () => {
      setLoading(true)
      await getAllProducts();
      await getBrands();
      await getCategories();
      setLoading(false)
    }
    makeAPIcalls()
    return () => {
      // Clean up the observer when component unmounts
      const hiddenElements = document.querySelectorAll('.hidden-css');
      hiddenElements.forEach((el) => observerRef.current.unobserve(el));
    };
  }, [])
  useEffect(() => {
    // Observe hidden elements when loading is false
    if (!loading) {
      const hiddenElements = document.querySelectorAll('.hidden-css');
      hiddenElements.forEach((el) => observerRef.current.observe(el));
    }
  }, [loading]);
  const getBrandProducts = async (id) => {
    await axios.get(`https://api.techcoredistribution.com/api/brand-products/${id}`).then((res) => {
      console.log(res.data);
      setProducts(res.data)

    })
  }
  const categoryProducts = async (id) => {
    await axios.get(`https://api.techcoredistribution.com/api/category-product/${id}`).then((res) => {
      console.log(res.data);
      setProducts(res.data)
    })
  }
  return (
    <>
      {
        loading &&
        <div className='h-screen w-screen bg-white grid place-items-center'>
          <img height={400} src="/assets/loading-animation.gif" alt="" />
        </div>
      }
      <div className='pt-20 md:pt-20 z-[2] min-h-screen '>
        <div className='w-1/4 md:w-1/6 h-screen text-sm fixed bg-white drop-shadow-xl z-[6] '>
          <div className='flex flex-col p-5'>
            <p className='font-black text-gray-500 text-xl uppercase'>Filters</p>
          </div>
          <div className='flex flex-col items-start justify-start gap-2 p-5'>
            <p className='text-lg font-medium'>Categories</p>

            {
              categories.map(cat => (
                <div key={cat.id} className='flex flex-row gap-2'>
                  <input checked={
                    selectedOption === cat.category_name
                  }
                    onChange={() => {
                      handleCheckboxChange(cat.category_name)
                      console.log(cat.category_name)
                      categoryProducts(cat.id)
                    }}
                    type="checkbox" name={cat.category_name} id="" value={cat.id} />
                  <label htmlFor={cat.category_name} >{cat.category_name}</label>
                </div>
              ))
            }

          </div>
          <div className='flex flex-col items-start justify-start gap-2 p-5 '>
            <p className='text-lg font-medium'>Brands</p>
            <div className='flex flex-row gap-2'>
              <input
                onChange={(e) => {
                  handleCheckboxChange('all')
                  console.log(e.target.value)
                  getAllProducts()
                }}
                checked={
                  selectedOption === 'All'
                }
                type="checkbox" name={'All'} id="" value={'All'} />
              <label htmlFor={'All'} >All</label>
            </div>
            {
              brands.map(brand => (
                <div key={brand.id} className='flex flex-row gap-2'>
                  <input
                    checked={
                      selectedOption === brand.brand_name
                    }
                    onChange={(e) => {
                      handleCheckboxChange(brand.brand_name)
                      getBrandProducts(brand.id)
                      console.log(e.target.value)
                    }}
                    type="checkbox" value={brand.id} />
                  <label htmlFor='vigo' >{brand.brand_name}</label>
                </div>
              ))
            }

          </div>
          <div className='uppercase font-medium p-5'>
            showing: {selectedOption}
          </div>
        </div>
        <div className='w-4/6 md:w-4/5 float-right flex flex-row flex-wrap gap-5 items-center justify-center p-5 products-page hidden-css'>
          {
            productsData === 0 ? <div>No Products Available</div> : productsData.map(product => (
              <Link to={'/product/' + product.id} key={product.id} className=''>
                < ProductCard name={product.product_name} imgUrl={product.product_thumbnail} id={product.id} key={product.id} />
              </Link>
            ))
          }
        </div>
      </div>
    </>
  )
}

export default Products