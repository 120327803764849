import React from 'react'

const AgePolicy = () => {
    return (
        <section className='w-full flex flex-col  pt-32 min-h-[82vh] bg-[url("/public/assets/background.jpg")] bg-center bg-repeat bg-contain '>
            <div className='text-justify p-5 flex flex-col justify-between gap-5 '>

                <p className=' text-center text-4xl font-black uppercase bg-clip-text bg-gradient-to-b from-slate-700 to-red-500 text-transparent'>Age Verification and Compliance</p>
                <div className=' text-center'>
                    At Tech Core Wholesale LLC, we sell vape and vape-related products. To make a purchase, you must meet the legal smoking/vaping age set by your state. Faking your age or using illegal methods to obtain our products is against the law and will be reported to authorities.
                </div>
                <div className='flex flex-col gap-2' >
                    <div className='font-bold text-2xl '>Verification Process</div>
                    We may use a third-party age verification system. You may need to answer some questions and provide an ID with a signed authorization note.
                </div>

                <div className='flex flex-col gap-2'>
                    <div className='font-bold text-2xl '>Order Cancellation</div>
                    Tech Core Wholesale LLC reserves the right to refuse service and cancel orders if we suspect inaccurate information has been provided.
                </div>
            </div>

        </section >
    )
}

export default AgePolicy